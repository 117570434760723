import { ActionType, getType } from 'typesafe-actions';

import * as geolocatorActions from '@actions/GeolocatorActions';
import * as mapActions from '@actions/MapActions';
import { FlyToInterpolator, TransitionInterpolator } from 'react-map-gl';

export const mapMaxZoom = 15;

export interface IMapViewport {
  latitude: number;
  longitude: number;
  zoom?: number;
  transitionDuration?: number | 'auto' | undefined;
  transitionInterpolator?: TransitionInterpolator;
}

/**
 * INITIAL_STATE
 */
export interface IMapState {
  readonly error?: Error;
  readonly isFetching: boolean;
  readonly lastUpdated: number;
  readonly viewport: IMapViewport;
}

export const initialMapState: IMapState = {
  lastUpdated: 0,
  error: undefined,
  isFetching: false,
  viewport: {
    latitude: 33.447358551630636,
    longitude: -112.06838446279167,
    zoom: 8,
    transitionInterpolator: undefined
  }
};

const getCappedZoom = (zoom: number) =>
  zoom <= mapMaxZoom ? zoom : mapMaxZoom;

/**
 * REDUCER
 */
export const MapReducer = (
  state: IMapState = initialMapState,
  action: ActionType<typeof mapActions & typeof geolocatorActions>
): IMapState => {
  switch (action.type) {
    case getType(mapActions.onViewportChange):
      return {
        ...state,
        lastUpdated: Date.now(),
        viewport: {
          ...state.viewport,
          ...action.payload,
          latitude: action.payload.latitude || state.viewport.latitude,
          longitude: action.payload.longitude || state.viewport.longitude,
          zoom: getCappedZoom(action.payload.zoom || state.viewport.zoom || 8)
        }
      };

    case getType(mapActions.onFlyTo):
      return {
        ...state,
        lastUpdated: Date.now(),
        viewport: {
          ...state.viewport,
          latitude: action.payload.latitude || state.viewport.latitude,
          longitude: action.payload.longitude || state.viewport.longitude,
          zoom: getCappedZoom(action.payload.zoom || state.viewport.zoom || 8),

          transitionDuration: 750,
          transitionInterpolator: new FlyToInterpolator()
        }
      };

    case getType(mapActions.zoomIn):
      return {
        ...state,
        lastUpdated: Date.now(),
        viewport: {
          ...state.viewport,
          zoom: getCappedZoom((state.viewport.zoom || 8) + 1)
        }
      };

    case getType(mapActions.zoomOut):
      return {
        ...state,
        lastUpdated: Date.now(),
        viewport: {
          ...state.viewport,
          zoom: getCappedZoom((state.viewport.zoom || 8) - 1)
        }
      };

    default:
      return state;
  }
};
