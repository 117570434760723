import { IMapFilters } from '@reducers/filter';

import { createAction } from 'typesafe-actions';

/*
 * FILTERS
 */

export const onFiltersChange = createAction('map/FILTERS_CHANGE')<
  IMapFilters
>();
