import { ActionType, getType } from 'typesafe-actions';

import * as filterActions from '@actions/FilterActions';
import { Mark } from '@material-ui/core';

export enum HigherLowerNone {
  Higher,
  Lower,
  NoPref
}

export interface MinMax {
  max: number;
  min: number;
}

export interface NumberRange {
  bounds: {
    minMax: MinMax;
    marks?: boolean | Mark[];
    step?: number;
  };
  user: MinMax;
}

export interface NumberValue {
  bounds: MinMax;
  user: number;
}

export interface IMapFilters {
  readonly preferCrowdAge: NumberRange;
  readonly preferIncome: NumberRange;
  readonly preferHomePrice: NumberRange;
  readonly preferRentPrice: NumberRange;
  readonly preferEducation: HigherLowerNone;
  readonly preferSchoolRatings: HigherLowerNone;
  readonly preferHigherAirbnbAbility: HigherLowerNone;
  readonly preferCrimeRate: HigherLowerNone;
}

/**
 * INITIAL_STATE
 */
export interface IFilterState {
  readonly error?: Error;
  readonly isFetching: boolean;
  readonly lastUpdated: number;
  readonly filters: IMapFilters;
}

export const defaultMapFilters = {
  preferCrowdAge: {
    bounds: {
      minMax: { max: 80, min: 20 },
      marks: true,
      step: 5
    },
    user: { max: 60, min: 20 }
  },
  preferIncome: {
    bounds: {
      minMax: { max: 150000, min: 40000 },
      marks: true,
      step: 10000
    },
    user: { max: 150000, min: 40000 }
  },
  preferHomePrice: {
    bounds: {
      minMax: { max: 2000000, min: 100000 },
      marks: true,
      step: 100000
    },
    user: { max: 2000000, min: 100000 }
  },
  preferRentPrice: {
    bounds: {
      minMax: { max: 2600, min: 300 },
      marks: true,
      step: 100
    },
    user: { max: 2600, min: 300 }
  },
  preferEducation: HigherLowerNone.Higher,
  preferSchoolRatings: HigherLowerNone.Higher,
  preferHigherAirbnbAbility: HigherLowerNone.Higher,
  preferCrimeRate: HigherLowerNone.Lower
};

export const initialFilterState: IFilterState = {
  lastUpdated: 0,
  error: undefined,
  isFetching: false,
  filters: defaultMapFilters
};

/**
 * REDUCER
 */
export const FilterReducer = (
  state: IFilterState = initialFilterState,
  action: ActionType<typeof filterActions>
): IFilterState => {
  switch (action.type) {
    case getType(filterActions.onFiltersChange):
      return {
        ...state,
        lastUpdated: Date.now(),
        filters: {
          ...state.filters,
          ...action.payload
        }
      };

    default:
      return state;
  }
};
