import { ActionType, getType } from 'typesafe-actions';

import * as actions from '@actions/LieuucationActions';
import { FeathersError } from '@feathersjs/errors';
import { ILieuucation } from '@lieuu/core';
// import getFakeLieuucation from './getFakeLieuucation';

/**
 * INITIAL_STATE
 */
export interface ILieuucationState {
  readonly error?: FeathersError;
  readonly isFetching: boolean;
  readonly lastUpdated: number;
  readonly lieuucations: ILieuucation[];
}

const initialLieuucations: ILieuucation[] = [];

export const initialLieuucationState: ILieuucationState = {
  error: undefined,
  isFetching: false,
  lastUpdated: 0,
  lieuucations: initialLieuucations
};

/**
 * REDUCER
 */

export const LieuucationReducer = (
  state: ILieuucationState = initialLieuucationState,
  action: ActionType<typeof actions>
): ILieuucationState => {
  switch (action.type) {
    case getType(actions.getLieuucations.request):
      return {
        ...state,
        error: undefined,
        isFetching: true,
        lieuucations: initialLieuucations
      };
    case getType(actions.getLieuucations.success):
      const addedLieuucations = action.payload;

      const lieuucationsSet = new Set([
        ...state.lieuucations,
        ...addedLieuucations
      ]);

      return {
        ...state,
        error: undefined,
        isFetching: false,
        lastUpdated: Date.now(),
        lieuucations: [...lieuucationsSet]
      };

    case getType(actions.getLieuucations.failure):
      return {
        ...state,
        error: action.payload,
        isFetching: false
      };

    case getType(actions.getLieuucationById.request):
      return {
        ...state,
        error: undefined,
        isFetching: true
      };
    case getType(actions.getLieuucationById.success):
      if (!action.payload) {
        return state;
      }

      if (
        state.lieuucations &&
        action.payload._id &&
        state.lieuucations.some(
          (e) => e._id === (action.payload && action.payload._id)
        )
      ) {
        return state;
      }

      const lieuucations = [...state.lieuucations, action.payload];

      return {
        ...state,
        lieuucations,
        error: undefined,
        isFetching: false,
        lastUpdated: Date.now()
      };
    case getType(actions.getLieuucationById.failure):
      return {
        ...state,
        error: action.payload,
        isFetching: false
      };

    default:
      return state;
  }
};
