import { ActionType, getType } from 'typesafe-actions';

import * as actions from '@actions/GeolocatorActions';

/**
 * INITIAL_STATE
 */
export interface IGeoLocatorState {
  readonly lastUpdated: number;
  readonly error?: Error;
  readonly isFetching: boolean;
  location: {
    readonly latitude: number;
    readonly longitude: number;
  };
}

export const initialGeolocatorState: IGeoLocatorState = {
  lastUpdated: 0,
  error: undefined,
  isFetching: false,
  location: {
    latitude: 33.447358551630636,
    longitude: -112.06838446279167
  }
};

/**
 * REDUCER
 */
export const GeoLocatorReducer = (
  state: IGeoLocatorState = initialGeolocatorState,
  action: ActionType<typeof actions>
): IGeoLocatorState => {
  switch (action.type) {
    case getType(actions.getCurrentPosition.request):
      return {
        ...state,
        error: undefined,
        isFetching: true
      };
    case getType(actions.getCurrentPosition.success):
      return {
        ...state,
        error: undefined,
        isFetching: false,
        lastUpdated: Date.now(),
        location: {
          ...state.location,
          latitude: action.payload.latitude,
          longitude: action.payload.longitude
        }
      };
    case getType(actions.getCurrentPosition.failure):
      return {
        ...state,
        error: action.payload,
        isFetching: false
      };

    default:
      return state;
  }
};
