enum LieuuErrorId {
  NOT_AUTHORIZED
}

class LieuuError extends Error {
  id?: LieuuErrorId;

  constructor(message, id?: LieuuErrorId) {
    super(message);
    this.name = 'LieuuError';
    this.id = id;
  }
}

export { LieuuError, LieuuErrorId };
