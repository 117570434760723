import { IMapViewport } from '@reducers/map';
import { ViewportProps } from 'react-map-gl';
import { createAction } from 'typesafe-actions';

/*
 * VIEWPORT
 */

export const zoomIn = createAction('map/ZOOM_IN')<void>();

export const zoomOut = createAction('map/ZOOM_OUT')<void>();

export const onViewportChange = createAction('map/VIEWPORT_CHANGE')<
  Partial<ViewportProps> | IMapViewport
>();

export const onFlyTo = createAction('map/FLY_TO')<IMapViewport>();
