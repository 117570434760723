import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';

import { FilterReducer } from './filter';
import { GeoLocatorReducer } from './geolocator';
import { LieuucationReducer } from './lieuucation';
import { MapReducer } from './map';
import { SearchReducer } from './search';
import { SnackbarReducer } from './snackbar';
import { SupportRequestReducer } from './supportRequest';
import { UserReducer } from './user';

// Configure Redux store & reducers
export const rootReducer = combineReducers({
  user: UserReducer,
  map: MapReducer,
  filter: FilterReducer,
  geolocator: GeoLocatorReducer,
  lieuucation: LieuucationReducer,
  snackbar: SnackbarReducer,
  search: SearchReducer,
  supportRequest: SupportRequestReducer
});

export * from './filter';
export * from './user';
export * from './map';
export * from './lieuucation';
export * from './snackbar';
export * from './search';
export * from './geolocator';
export * from './supportRequest';

export type IStore = StateType<typeof rootReducer>;
